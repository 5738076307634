import React from "react";
import Icon from "../commons/Icon";

type Props = {
    className?: string;
    size?: number;
};

export default function PencilIcon({ className, size }: Props): React.ReactElement {
    return (
        <Icon className={className} size={size}>
            <path
                d="M17.4397 2.19019L15.8086 0.558944C15.0633 -0.186332 13.8508 -0.186297 13.1055 0.558944C12.4038 1.2607 1.68623 11.9791 0.969991 12.6954C0.893702 12.7716 0.842585 12.873 0.824374 12.9722L0.0088212 17.3766C-0.0227842 17.5473 0.0316376 17.7227 0.154438 17.8455C0.277379 17.9685 0.452808 18.0228 0.623351 17.9912L5.02733 17.1755C5.12911 17.1565 5.22916 17.1049 5.30418 17.0299L17.4397 4.89346C18.1867 4.14646 18.1868 2.93729 17.4397 2.19019ZM1.1855 16.8144L1.67884 14.1501L3.8496 16.321L1.1855 16.8144ZM4.93132 15.9112L2.08862 13.0683L12.4671 2.68902L15.3098 5.53193L4.93132 15.9112ZM16.6939 4.14769L16.0555 4.78616L13.2128 1.94325L13.8512 1.30478C14.1852 0.970728 14.7287 0.970693 15.0628 1.30478L16.6939 2.93603C17.0288 3.27089 17.0288 3.81279 16.6939 4.14769Z"
                fill="#90E4FF"
            />
        </Icon>
    );
}
