export const LGND_ADDRESS = process.env.REACT_APP_ADDRESS_LGND;
export const PLATFORM_ADDRESS = process.env.REACT_APP_ADDRESS_PLATFORM;
const NFT_ADDRESS = process.env.REACT_APP_ADDRESS_NFT;
const NFT_ADDRESS_2 = process.env.REACT_APP_ADDRESS_NFT_2;
const NFT_ADDRESS_3 = process.env.REACT_APP_ADDRESS_NFT_3;
export const NFT_ADDRESSES = [
    NFT_ADDRESS,
    NFT_ADDRESS_2,
    NFT_ADDRESS_3
];
const NFT_MINTING_ADDRESS = process.env.REACT_APP_ADDRESS_NFT_MINTING;
const NFT_MINTING_ADDRESS_2 = process.env.REACT_APP_ADDRESS_NFT_MINTING_2;
const NFT_MINTING_ADDRESS_3 = process.env.REACT_APP_ADDRESS_NFT_MINTING_3;
export const NFT_MINTING_ADDRESSES = [
    NFT_MINTING_ADDRESS,
    NFT_MINTING_ADDRESS_2,
    NFT_MINTING_ADDRESS_3
];
export const STAKING_ADDRESS = process.env.REACT_APP_ADDRESS_STAKING;