import React from "react";
import Icon from "../commons/Icon";

type Props = {
    className?: string;
};

export default function WalletIcon({ className }: Props): React.ReactElement {
    return (
        <Icon className={className}>
            <path d="M18.8281 9.68742H18.4375V6.48429C18.4375 5.44793 17.6261 4.59793 16.6052 4.53527L12.4249 0.354922C11.9522 -0.117695 11.1833 -0.117617 10.7107 0.354922L9.96945 1.09613L9.22824 0.354922C8.75559 -0.117656 7.98672 -0.117656 7.51406 0.354922L3.33781 4.53117H1.95312C0.876172 4.53117 0 5.40734 0 6.48429V18.0468C0 19.1237 0.876172 19.9999 1.95312 19.9999H7.46094C7.67664 19.9999 7.85156 19.825 7.85156 19.6093C7.85156 19.3936 7.67664 19.2187 7.46094 19.2187H1.95312C1.30695 19.2187 0.78125 18.693 0.78125 18.0468V6.48429C0.78125 5.83812 1.30695 5.31242 1.95312 5.31242H16.4844C17.1305 5.31242 17.6562 5.83812 17.6562 6.48429V9.68742H12.9688C11.5471 9.68742 10.3906 10.8439 10.3906 12.2655C10.3906 13.6871 11.5471 14.8437 12.9688 14.8437H17.6562V18.0468C17.6562 18.693 17.1305 19.2187 16.4844 19.2187H10.9765C10.7608 19.2187 10.5859 19.3936 10.5859 19.6093C10.5859 19.825 10.7608 19.9999 10.9765 19.9999H16.4844C17.5613 19.9999 18.4375 19.1237 18.4375 18.0468V14.8437H18.8281C19.4743 14.8437 20 14.318 20 13.6718V10.8593C20 10.2131 19.4743 9.68742 18.8281 9.68742ZM8.06652 0.907383C8.23453 0.739375 8.50781 0.739375 8.67582 0.907383L9.41707 1.64859L6.53445 4.53117H4.44273L8.06652 0.907383ZM7.6393 4.53117L11.2631 0.907383C11.4312 0.739375 11.7045 0.739414 11.8725 0.907383L15.4963 4.53117H7.6393ZM19.2188 13.6718C19.2188 13.8872 19.0435 14.0624 18.8281 14.0624H12.9688C11.978 14.0624 11.1719 13.2563 11.1719 12.2655C11.1719 11.2748 11.978 10.4687 12.9688 10.4687H18.8281C19.0435 10.4687 19.2188 10.6439 19.2188 10.8593V13.6718Z" />
            <path d="M12.9688 11.8748C12.866 11.8748 12.7652 11.9165 12.6926 11.9892C12.6199 12.0619 12.5781 12.1626 12.5781 12.2654C12.5781 12.3681 12.6199 12.4689 12.6926 12.5415C12.7652 12.6142 12.866 12.656 12.9688 12.656C13.0715 12.656 13.1723 12.6142 13.2449 12.5415C13.3175 12.4689 13.3594 12.3681 13.3594 12.2654C13.3594 12.1626 13.3176 12.0619 13.2449 11.9892C13.1723 11.9165 13.0715 11.8748 12.9688 11.8748Z" />
            <path d="M9.21875 19.2185C9.11559 19.2185 9.01523 19.2603 8.94258 19.333C8.86992 19.4057 8.82812 19.506 8.82812 19.6091C8.82812 19.7119 8.86988 19.8126 8.94258 19.8853C9.01523 19.9579 9.11602 19.9998 9.21875 19.9998C9.32148 19.9998 9.42227 19.958 9.49488 19.8853C9.56754 19.8126 9.60938 19.7119 9.60938 19.6091C9.60938 19.5064 9.56758 19.4056 9.49488 19.333C9.42227 19.2603 9.32148 19.2185 9.21875 19.2185Z" />
        </Icon>
    );
}
