import React from "react";
import Icon from "../commons/Icon";

type Props = {
    className?: string;
}

export default function DiscordIcon({ className }: Props): React.ReactElement {
    return (
        <Icon className={className}>
            <path
                d="M20.3,4.4c-1.6-0.7-3.2-1.2-5-1.5c-0.2,0.4-0.4,0.8-0.6,1.3c-1.8-0.3-3.7-0.3-5.5,0C9.1,3.8,8.8,3.3,8.6,2.9
	                c-1.7,0.3-3.4,0.8-5,1.5c-3.1,4.6-4,9.2-3.6,13.6l0,0c1.8,1.4,3.9,2.4,6.1,3c0.5-0.7,0.9-1.4,1.3-2.1c-0.7-0.3-1.4-0.6-2-1
	                c0.2-0.1,0.3-0.3,0.5-0.4c3.8,1.8,8.3,1.8,12.1,0c0.2,0.1,0.3,0.3,0.5,0.4c-0.7,0.4-1.3,0.7-2.1,1c0.4,0.7,0.8,1.4,1.3,2.1
	                c2.2-0.7,4.2-1.7,6.1-3l0,0C24.4,12.9,23,8.4,20.3,4.4z M8,15.3c-1.2,0-2.2-1.1-2.2-2.4s0.9-2.4,2.2-2.4s2.2,1.1,2.2,2.4
	                C10.2,14.2,9.2,15.3,8,15.3z M16,15.3c-1.2,0-2.2-1.1-2.2-2.4s0.9-2.4,2.2-2.4s2.2,1.1,2.2,2.4C18.1,14.2,17.2,15.3,16,15.3z"
            />
        </Icon>
    );
}
